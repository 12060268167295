import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
 #root {
  white-space: pre-line;
 }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  button {
    cursor: pointer;
  }

  body,
  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  body {
    min-height: 100vh;
  }

  a {
    text-decoration: none;
  }

  html {
    scroll-behavior: smooth;

    @media (max-width: 1080px){
      font-size: 93.75%; //15px
    }
    @media (max-width: 720px) {
      font-size: 87.5%; //14px
    }
    @media (max-width: 500px) {
      font-size: 100% //back to 16px
    }

  }

`
