import * as S from './styles'

const availableFonts = [
  'montserrat-regular',
  'montserrat-medium',
  'montserrat-bold',
  'montserrat-black',
  'roboto-regular',
  'roboto-medium',
  'roboto-bold'
] as const

type Font = typeof availableFonts[number]

const availableTags = [
  'p',
  'strong',
  'span',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'label',
  'a'
] as const

type Tag = typeof availableTags[number]

export type TextProps = {
  color?: string
  id?: string
  size?: React.CSSProperties['fontSize']
  font?: Font
  align?: 'left' | 'center' | 'right'
  italic?: boolean
  upperCase?: boolean
  loading?: boolean
  style?: React.CSSProperties
  children?: React.ReactNode
  weight?: number
  ellipsis?: boolean
  numberOfLines?: number
  lowerCase?: boolean
  capitalize?: boolean
  tag?: Tag
  underline?: boolean
  href?: string
  onClick?: () => void
  className?: string
  htmlFor?: string
}

const Text: React.FC<TextProps> = ({
  size,
  italic,
  id,
  upperCase,
  loading,
  children,
  weight,
  ellipsis,
  style,
  lowerCase,
  capitalize,
  underline,
  onClick,
  className,
  color = '#2980E2',
  font = 'montserrat-regular',
  numberOfLines = 1,
  tag = 'p',
  align = 'left',
  ...props
}: TextProps) => {
  return (
    <S.Text
      {...props}
      id={id}
      as={tag}
      color={color}
      size={size}
      font={font}
      align={align}
      italic={italic}
      upperCase={upperCase}
      underline={underline}
      weight={weight}
      style={style}
      ellipsis={ellipsis}
      numberOfLines={numberOfLines}
      lowerCase={lowerCase}
      capitalize={capitalize}
      tag={tag}
      onClick={onClick}
      className={className}
    >
      {children}
    </S.Text>
  )
}

export default Text
