import { FC } from 'react'
import { Text } from 'components/Text'

import * as S from './styles'

type Props = {
  backgroundColor?: string
  textColor?: string
  label?: string
  marginTop?: string
  goTo?: string
  gradient?: [string, string]
}

const Button: FC<Props> = (props) => {
  const { backgroundColor, textColor, label, marginTop, goTo, gradient } = props

  return (
    <S.Button
      backgroundColor={backgroundColor}
      marginTop={marginTop}
      gradient={gradient}
    >
      <Text
        tag="a"
        href={goTo}
        upperCase
        font="montserrat-bold"
        size="1.4rem"
        align="center"
        color={textColor ? textColor : '#2981e2'}
      >
        {label}
      </Text>
    </S.Button>
  )
}

export default Button
