import { FC, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Text } from 'components/Text'
import { Input } from '../Input'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCreateContact } from '@hashtagbe/api'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Schema } from './_constants'
import { toast } from 'react-toastify'
import { parsePayload } from './utils'
import SyncLoader from 'react-spinners/SyncLoader'

import * as S from './styles'
import ShouldRender from 'components/ShouldRender'

export type InputNames = {
  firstName: string
  lastName: string
  email: string
  phone: string
  message: string
}

type Props = InputNames

type SectionProps = {
  isModalVersion: boolean
  closeModal?(): void
  defaultContactMessage?: string
}

const Section: FC<SectionProps> = ({
  isModalVersion,
  closeModal,
  defaultContactMessage
}) => {
  const { t } = useTranslation()

  const [createContact, { isLoading, isSuccess, error, reset: resetRequest }] =
    useCreateContact()

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<InputNames>({ resolver: yupResolver(Schema()) })
  const onSubmit: SubmitHandler<Props> = (data) => {
    createContact(data)
  }

  useEffect(() => {
    if (error) {
      toast.error(error?.message)
    }
  }, [error])

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('ui.toast.contactSuccess'))
      reset()
      resetRequest()
      closeModal?.()
      window.scrollTo(0, 0)
    }
  }, [isSuccess, reset, resetRequest])

  return (
    <S.Container isModalVersion={isModalVersion}>
      <Text
        tag="h2"
        align="center"
        font="montserrat-bold"
        size="3.125rem"
        color={isModalVersion ? '#fff' : '#2980E2'}
      >
        {isModalVersion
          ? t('ui.contactForm.requestYourTrial')
          : t('ui.contactForm.getInContact')}
      </Text>
      <ShouldRender if={isModalVersion}>
        <Text
          tag="h3"
          align="center"
          font="montserrat-regular"
          weight={300}
          size="29px"
          color="#fff"
          style={{ marginTop: '-15px', width: '70%', lineHeight: 1.1 }}
        >
          {t('ui.contactForm.consultant')}
        </Text>
      </ShouldRender>
      <S.Form
        onSubmit={handleSubmit(parsePayload(onSubmit))}
        isModalVersion={isModalVersion}
      >
        <Input
          label={t('ui.contactForm.name')}
          id="firstName"
          name="firstName"
          register={register}
          errors={errors}
          placeholder={t('ui.placeholder.name')}
          isModal={isModalVersion}
        />
        <Input
          label={t('ui.contactForm.surname')}
          id="lastName"
          name="lastName"
          register={register}
          errors={errors}
          placeholder={t('ui.placeholder.surname')}
          isModal={isModalVersion}
        />
        <Input
          type="tel"
          label={t('ui.contactForm.phone')}
          id="phone"
          name="phone"
          register={register}
          errors={errors}
          placeholder={t('ui.placeholder.phone')}
          isModal={isModalVersion}
        />
        <Input
          label={t('ui.contactForm.email')}
          id="email"
          name="email"
          register={register}
          errors={errors}
          placeholder={t('ui.placeholder.email')}
          isModal={isModalVersion}
        />
        <Input
          label={t('ui.contactForm.message')}
          textarea
          id="message"
          name="message"
          defaultValue={defaultContactMessage || ''}
          register={register}
          errors={errors}
          placeholder={t('ui.placeholder.message')}
          isModal={isModalVersion}
        />
        <S.PrivacyText color={isModalVersion ? '#fff' : '#646464'}>
          <Trans
            i18nKey="ui.contactForm.privacy"
            t={t}
            components={[
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href="https://be-code-of-conduct.hashtag.be/"
                target="_blank"
                rel="noreferrer"
              />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href="https://be-privacy-policy-sandbox.hashtag.be/"
                target="_blank"
                rel="noreferrer"
              />
            ]}
          ></Trans>
        </S.PrivacyText>
        <S.SubmitButton
          isModalVersion={isModalVersion}
          onSubmit={handleSubmit(onSubmit)}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <S.SpinnerContainer>
              <SyncLoader
                color={isModalVersion ? '#29A9DF' : '#fff'}
                size={13}
              />
            </S.SpinnerContainer>
          ) : (
            <Text
              upperCase
              font="roboto-bold"
              size="1.875rem"
              align="center"
              color={isModalVersion ? '#29A9DF' : '#fff'}
            >
              {isModalVersion
                ? t('ui.contactForm.request')
                : t('ui.contactForm.send')}
            </Text>
          )}
        </S.SubmitButton>
      </S.Form>
    </S.Container>
  )
}

export default Section
