import { Contacts } from '@hashtagbe/api'
import { SubmitHandler } from 'react-hook-form'
import { InputNames } from './Section'

export type Props = InputNames

export const parsePayload =
  (onSubmitFunc: SubmitHandler<Props>) => (data: Props) => {
    const parsedData: Contacts.Create = {
      email: data?.email.toLowerCase(),
      firstName: data?.firstName.trim(),
      lastName: data?.lastName.trim(),
      phone: data?.phone,
      message: data?.message
    }

    onSubmitFunc(parsedData)
  }
