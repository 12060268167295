import styled from 'styled-components'
import BackgroundLarge from 'assets/images/intro-background.png'
import BackgroundMobile from 'assets/images/intro-background-small.png'

export const Container = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  background: url(${BackgroundLarge}) left top no-repeat;
  padding-bottom: 220px;

  @media (max-width: 885px) {
    padding-bottom: 280px;
  }

  @media (max-width: 500px) {
    background: url(${BackgroundMobile}) no-repeat;
    background-size: cover;
    padding-bottom: 92px;
  }
`
