import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  max-width: 1925px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  padding-bottom: 260px;
  z-index: 3;

  @media (max-width: 1420px) {
    padding-bottom: 330px;
  }

  @media (max-width: 500px) {
    padding-bottom: 95px;
  }
`

export const BackgroundShape = styled.img`
  position: absolute;
  right: 0;
  top: 100px;

  @media (max-width: 1355px) {
    top: 745px;
    width: 91vw;
    height: 82vw;
  }

  @media (max-width: 500px) {
    top: 250px;
  }

  @media (max-width: 320px) {
    top: 300px;
  }
`

export const BackgroundLeftCircle = styled.img`
  position: absolute;
  left: 0;
  top: 365px;

  @media (max-width: 1355px) {
    display: none;
  }
`
