import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1925px;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(112.37deg, #29abe2 8%, #2973e2 74.06%);
`

export const ButtonContainer = styled.div`
  max-width: 1295px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: space-between;
  justify-content: flex-start;
  padding-bottom: 200px;

  @media (max-width: 1355px) {
    margin-left: 50px;
    margin-right: 50px;
  }

  @media (max-width: 500px) {
    justify-content: center;
    margin-left: 28px;
    margin-right: 28px;
    padding-bottom: 55px;
  }
`
