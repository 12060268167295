import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1295px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 170px;
  margin-top: 91px;

  .section-text__container {
    padding-top: 0;
  }

  .section-text__title {
    font-size: 3.5rem !important;
  }

  .section-text__line {
    display: none;
  }

  @media (max-width: 1355px) {
    gap: 50px;
  }

  @media (max-width: 885px) {
    gap: 0;
  }

  @media (max-width: 500px) {
    margin-top: 49px;

    .section-text__title {
      font-size: 2.5rem !important;
    }

    .section-text__subtitle {
      font-size: 1.5rem !important;
    }
  }

  @media (max-width: 320px) {
    .section-text__title {
      font-size: 1.875rem !important;
    }

    .section-text__subtitle {
      font-size: 1.125rem !important;
    }
  }
`

export const TextAndIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;

  @media (max-width: 500px) {
    gap: 13px;

    p {
      font-size: 0.938rem;
      max-width: 260px;
    }
  }
`

export const Icon = styled.img`
  width: 30px;
  height: 30px;
`

export const PhonesPictureMobile = styled.img`
  margin-top: 10px;
  display: none;

  @media (max-width: 500px) {
    width: 100%;
    display: initial;
  }
`

export const PhonesPictureContainerMobile = styled.div`
  position: relative;
  display: none;

  @media (max-width: 500px) {
    display: flex;
    align-items: flex-end;
    width: 100%;
    aspect-ratio: 7/10;
    margin-top: 10px;
  }
`

export const PhonesPictureContainer = styled.div`
  position: relative;
  width: 470px;
  height: 670px;
  display: flex;
  align-items: center;

  @media (max-width: 820px) {
    display: none;
  }
`

export const LeftSidePhoneContainer = styled.div`
  position: absolute;
  z-index: 3;
  left: 10px;
  width: 261px;
  height: 519px;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.7));

  @media (max-width: 500px) {
    width: 61%;
    height: 83%;
  }
`

export const FrameContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PhoneFrame = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
`

export const Video = styled.video`
  position: absolute;
  width: 95%;
  top: 0px;
  border-radius: 38px;
`

export const VideoSource = styled.source``

export const RightSidePhone = styled.img`
  position: absolute;
  left: 60px;
  bottom: 100px;
  width: 406px;
  height: 591px;

  @media (max-width: 500px) {
    width: 90%;
    height: 90%;
    left: 16%;
    bottom: 8%;
  }
`
