import React from 'react'
import ReactDOM from 'react-dom/client'
import GlobalStyle from './styles/global'
import Application from './Application'
import appConfig from 'services/app'
import { BeAPI } from '@hashtagbe/api'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BeAPI target={appConfig.target} networkSlug="">
    <Application />
    <GlobalStyle />
  </BeAPI>
)
