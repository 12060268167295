import { FC } from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from 'styles/theme'
import { Intro } from 'components/Intro'
import { TechSuperpower } from 'components/TechSuperpower'
// import { LanguageSelector } from 'components/LanguageSelector'
import { WhatWeDo } from 'components/WhatWeDo'
import { Scalability } from 'components/Scalability'
import { Pricing } from 'components/Pricing'
import { Partners } from 'components/Partners'
import { RequestDemo } from 'components/RequestDemo'
import { Footer } from 'components/Footer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './i18n'

const Application: FC = () => (
  <ThemeProvider theme={theme}>
    <Intro />
    <TechSuperpower />
    <WhatWeDo />
    <Scalability />
    <Partners />
    <Pricing />
    <RequestDemo />
    <Footer />
    {/* <LanguageSelector /> */}
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      theme="light"
      draggable
      pauseOnHover
    />
  </ThemeProvider>
)

export default Application
