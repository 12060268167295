import styled from 'styled-components'

type ContainerProps = {
  width: number
  mediumWidth: number
  mobileWidth: number
  gradient: string
  top: string
  inView: boolean
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  right: 0;
  top: ${({ top }) => top};
  overflow: hidden;
  display: flex;
  width: ${({ width }) => `${width}vw`};
  max-width: ${({ width }) => `${(width * 1920) / 100}px`};
  height: 76px;
  background: ${({ gradient }) => `linear-gradient(${gradient})`};
  border-radius: 100px 0px 0px 100px;
  padding: 10px 0 10px 40px;

  p {
    ${({ inView }) =>
      inView &&
      `
      animation: text-fadeIn 2s;
  `}
  }

  ${({ inView }) =>
    inView &&
    `
      animation: bulletpoint-fadeIn 1.5s;
  `}

  @media (max-width: 1030px) {
    position: relative;
    border-radius: 0 100px 100px 0;
    width: ${({ mediumWidth }) => `${mediumWidth}vw`};
    top: 0;

    p {
      padding-right: 15px;
    }
  }

  @media (max-width: 500px) {
    height: 32px;
    min-height: 32px;
    padding: 0;
    align-items: center;
    width: ${({ mobileWidth }) => `${mobileWidth}px`};

    p {
      font-size: 1.125rem;
      padding: 0 0 0 14px;
      width: 100%;
    }
  }

  @media (max-width: 340px) {
    width: ${({ mediumWidth }) => `${mediumWidth}vw`};
    height: 52px;

    p {
      padding-right: 10px;
      font-size: 1rem;
      line-height: 1;
    }
  }

  @keyframes bulletpoint-fadeIn {
    0% {
      width: 0;
    }
    50% {
      width: ${({ width }) => 0.5 * width};
    }
    100% {
      width: ${({ width }) => width};
    }
  }

  @keyframes text-fadeIn {
    0% {
      opacity: 0;
    }

    40% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
