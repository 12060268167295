import { MouseEventHandler, useCallback } from 'react'
import * as S from './styles'

type Props = {
  onClick(): void
}

const Close: React.FC<Props> = ({ onClick }: Props) => {
  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation()

      onClick?.()
    },
    [onClick]
  )

  return (
    <S.IconContainer onClick={handleClick}>
      <S.Icon />
    </S.IconContainer>
  )
}

export default Close
