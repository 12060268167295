import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 500px) {
    gap: 5px;

    label {
      font-size: 1.125rem;
    }
  }
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const TextInput = styled.input<{ isModal?: boolean }>`
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 18px;
  outline: none;
  font-size: 1.25rem;
  font-family: 'roboto-regular';
  color: #343434;
  box-shadow: 0px 5px 11px -3px rgba(0, 0, 0, 0.03);

  ${({ isModal }) =>
    !isModal ? 'border: 1px solid #b5b5b5;' : 'border: none;'}

  &:focus {
    outline: 2px solid #484848;
  }

  @media (max-width: 500px) {
    border-radius: 100px;
    padding: 12px 20px;
    font-size: 1rem;
  }
`

export const TextArea = styled.textarea<{ isModal?: boolean }>`
  resize: none;
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 18px;
  border: none;
  outline: none;
  font-size: 1.25rem;
  border: 1px solid #b5b5b5;
  font-family: 'roboto-regular';
  color: #343434;
  box-shadow: 0px 5px 11px -3px rgba(0, 0, 0, 0.03);

  ${({ isModal }) =>
    !isModal ? 'border: 1px solid #b5b5b5;' : 'border: none;'}

  &:focus {
    outline: 2px solid #484848;
  }

  @media (max-width: 500px) {
    padding: 12px 20px;
    font-size: 1rem;
  }
`
