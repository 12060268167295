import styled from 'styled-components'
import { MdOutlineClose } from 'react-icons/md'

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 18px;
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 19px;
  z-index: 999;

  @media (max-width: 770px) {
    width: 40px;
    height: 40px;
  }
`
export const Icon = styled(MdOutlineClose).attrs({
  size: 24,
  color: '#000'
})`
  @media (max-width: 770px) {
    width: 23px;
    height: 23px;
  }
`
