import { FC, useMemo } from 'react'
import BackgroundImage from 'assets/images/pricing-background.png'

import { useTranslation } from 'react-i18next'
import { Feature } from './Feature'
import { Model } from './Model'
import { ModelProps } from './_constants'
import i18n from 'i18n'
import * as S from './styles'

const Pricing: FC = () => {
  const { t } = useTranslation()

  const currentLanguage = i18n.language

  const models: ModelProps[] = useMemo(
    () => [
      {
        title: t('ui.starter'),
        id: 1,
        buttonText: t('ui.startTrial'),
        header: {
          price: 99,
          color: '#28ABE2'
        },
        features: [
          {
            info: 100,
            id: 1,
            type: 'number'
          },
          {
            info: 100,
            id: 2,
            type: 'storage'
          },
          {
            info: false,
            id: 3,
            type: 'check',
            extraInfo: t('ui.extraFee')
          },
          {
            info: true,
            id: 4,
            type: 'check'
          },
          {
            info: true,
            id: 5,
            type: 'check'
          },
          {
            info: true,
            id: 6,
            type: 'check'
          },
          {
            info: true,
            id: 7,
            type: 'check'
          },
          {
            info: true,
            id: 8,
            type: 'check'
          },
          {
            info: true,
            id: 9,
            type: 'check'
          },
          {
            info: true,
            id: 10,
            type: 'check'
          },
          {
            info: true,
            id: 11,
            type: 'check'
          },
          {
            info: true,
            id: 12,
            type: 'check'
          },
          {
            info: true,
            id: 13,
            type: 'check'
          },
          {
            info: true,
            id: 14,
            type: 'check'
          }
        ]
      },
      {
        title: t('ui.whyNot'),
        id: 1,
        buttonText: t('ui.startTrial'),
        header: {
          price: 290,
          color: '#2995E2'
        },
        features: [
          {
            info: 300,
            id: 1,
            type: 'number'
          },
          {
            info: 300,
            id: 2,
            type: 'storage'
          },
          {
            info: false,
            id: 3,
            type: 'check',
            extraInfo: t('ui.extraFee')
          },
          {
            info: true,
            id: 4,
            type: 'check'
          },
          {
            info: true,
            id: 5,
            type: 'check'
          },
          {
            info: true,
            id: 6,
            type: 'check'
          },
          {
            info: true,
            id: 7,
            type: 'check'
          },
          {
            info: true,
            id: 8,
            type: 'check'
          },
          {
            info: true,
            id: 9,
            type: 'check'
          },
          {
            info: true,
            id: 10,
            type: 'check'
          },
          {
            info: true,
            id: 11,
            type: 'check'
          },
          {
            info: true,
            id: 12,
            type: 'check'
          },
          {
            info: true,
            id: 13,
            type: 'check'
          },
          {
            info: true,
            id: 14,
            type: 'check'
          }
        ]
      },
      {
        title: t('ui.together'),
        id: 1,
        buttonText: t('ui.startTrial'),
        header: {
          price: 540,
          color: '#2980E2'
        },
        features: [
          {
            info: 600,
            id: 1,
            type: 'number'
          },
          {
            info: 600,
            id: 2,
            type: 'storage'
          },
          {
            info: true,
            id: 3,
            type: 'check'
          },
          {
            info: true,
            id: 4,
            type: 'check'
          },
          {
            info: true,
            id: 5,
            type: 'check'
          },
          {
            info: true,
            id: 6,
            type: 'check'
          },
          {
            info: true,
            id: 7,
            type: 'check'
          },
          {
            info: true,
            id: 8,
            type: 'check'
          },
          {
            info: true,
            id: 9,
            type: 'check'
          },
          {
            info: true,
            id: 10,
            type: 'check'
          },
          {
            info: true,
            id: 11,
            type: 'check'
          },
          {
            info: true,
            id: 12,
            type: 'check'
          },
          {
            info: true,
            id: 13,
            type: 'check'
          },
          {
            info: true,
            id: 14,
            type: 'check'
          }
        ]
      },
      {
        title: t('ui.booster'),
        id: 1,
        buttonText: t('ui.contactUs'),
        header: {
          price: t('ui.previousLimits'),
          color: '#0F64E2'
        },
        features: [
          {
            info: t('ui.unlimited'),
            id: 1,
            type: 'text'
          },
          {
            info: t('ui.unlimited'),
            id: 2,
            type: 'text'
          },
          {
            info: true,
            id: 3,
            type: 'check'
          },
          {
            info: true,
            id: 4,
            type: 'check'
          },
          {
            info: true,
            id: 5,
            type: 'check'
          },
          {
            info: true,
            id: 6,
            type: 'check'
          },
          {
            info: true,
            id: 7,
            type: 'check'
          },
          {
            info: true,
            id: 8,
            type: 'check'
          },
          {
            info: true,
            id: 9,
            type: 'check'
          },
          {
            info: true,
            id: 10,
            type: 'check'
          },
          {
            info: true,
            id: 11,
            type: 'check'
          },
          {
            info: true,
            id: 12,
            type: 'check'
          },
          {
            info: true,
            id: 13,
            type: 'check'
          },
          {
            info: true,
            id: 14,
            type: 'check'
          }
        ]
      }
    ],
    [currentLanguage]
  )

  const features = useMemo(
    () => [
      {
        id: 1,
        title: t('ui.users')
      },
      {
        id: 2,
        title: t('ui.cloud')
      },
      {
        id: 3,
        title: t('ui.personalizedApp'),
        subtitle: t('ui.appStores')
      },
      {
        id: 4,
        title: t('ui.webApp'),
        subtitle: t('ui.brandDomain')
      },
      {
        id: 5,
        title: t('ui.notificationBroadcasting'),
        subtitle: t('ui.emailPush')
      },
      {
        id: 6,
        title: t('ui.videoCMS'),
        subtitle: t('ui.cms')
      },
      {
        id: 7,
        title: t('ui.usefulLinks')
      },
      {
        id: 8,
        title: t('ui.roles'),
        subtitle: t('ui.differentRights')
      },
      {
        id: 9,
        title: t('ui.tagging')
      },
      {
        id: 10,
        title: t('ui.gamification')
      },
      {
        id: 12,
        title: t('ui.privacyManagement')
      },
      {
        id: 13,
        title: t('ui.analytics')
      },
      {
        id: 14,
        title: t('ui.sso'),
        subtitle: t('ui.ssoProviders')
      },
      {
        id: 15,
        title: t('ui.backup')
      }
    ],
    [currentLanguage]
  )

  return (
    <S.Container id="pricing">
      <S.Content>
        <S.TitleContainer style={{ width: '90%' }}>
          <S.Title
            color="#fff"
            font="montserrat-bold"
            size="50px"
            align="center"
          >
            {t('ui.pricing')}
          </S.Title>

          <S.Subtitle
            color="#fff"
            font="montserrat-regular"
            size="29px"
            align="center"
          >
            {t('ui.plansBilled')}
          </S.Subtitle>
        </S.TitleContainer>

        <S.Table>
          <S.Features>
            {features.map((feature) => (
              <Feature
                title={feature?.title}
                subtitle={feature?.subtitle}
                key={feature?.id}
              />
            ))}
          </S.Features>

          <S.Models>
            {models.map((model) => (
              <Model
                buttonText={model?.buttonText}
                header={model?.header}
                features={model?.features}
                id={model?.id}
                title={model?.title}
                key={model?.id}
              />
            ))}
          </S.Models>
        </S.Table>
      </S.Content>

      <S.BackgroundBottomLeftCircle src={BackgroundImage} />
      <S.BackgroundTopRightCircle src={BackgroundImage} />
    </S.Container>
  )
}

export default Pricing
