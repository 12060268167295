import { FC } from 'react'
import { Section } from './Section'
import BackgroundRightCircle from 'assets/images/right-circle.png'
import BackgroundLeftShape from 'assets/images/partners-shape.png'

import * as S from './styles'

const Partners: FC = () => (
  <S.Container id="partners">
    <Section />
    <S.BackgroundRightCircle src={BackgroundRightCircle} />
    <S.BackgroundLeftShape src={BackgroundLeftShape} />
  </S.Container>
)

export default Partners
