import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/Button'
import { Fade } from 'react-awesome-reveal'
import { Features } from './Features'
import { Section } from './Section'

import * as S from './styles'

const WhatWeDo: FC = () => {
  const { t } = useTranslation()

  return (
    <S.Container id="what-we-do">
      <Fade direction="left" triggerOnce>
        <Section />
        <Features />
        <S.ButtonContainer>
          <Button
            goTo="#pricing"
            textColor="#2981e2"
            backgroundColor="#fff"
            label={t('ui.general.startTrial')}
            marginTop="100px"
          />
        </S.ButtonContainer>
      </Fade>
    </S.Container>
  )
}

export default WhatWeDo
