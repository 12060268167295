import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/Button'
import { SectionText } from 'components/SectionText'
import { Text } from 'components/Text'
import NotebookImage from 'assets/images/macbook.png'

import * as S from './styles'

const Section: FC = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.LeftSideContainer>
        <SectionText
          isBlue
          title={t('ui.general.scalable')}
          extraText={
            <>
              <Text
                font="montserrat-black"
                size="1.813rem"
                color="#646464"
                weight={900}
                className="section-text__extra-text"
              >
                {t('ui.general.webApp')}
              </Text>
              <Text
                font="montserrat-black"
                size="1.7rem"
                color="#646464"
                weight={900}
                className="section-text__extra-text2"
              >
                <S.ExtraTextSpan>{'>'}</S.ExtraTextSpan>{' '}
                {t('ui.general.yesYouCan')}{' '}
                <S.ExtraTextSpan>{'<'}</S.ExtraTextSpan>
              </Text>
            </>
          }
          subTitle={t('ui.general.provide')}
        />

        <S.ButtonContainer>
          <Button
            goTo="#pricing"
            textColor="#fff"
            gradient={['#29ABE2', '#2973E2']}
            label={t('ui.general.requestDemo')}
          />
        </S.ButtonContainer>
      </S.LeftSideContainer>
      <S.RightSideContainer>
        <S.NotebookImage src={NotebookImage} />

        <Text
          tag="h3"
          font="montserrat-regular"
          size="1.125rem"
          align="center"
          color="#646464"
          weight={400}
          className="subtitle-mobile"
        >
          {t('ui.general.provide')}
        </Text>
        <S.MobileButtonContainer>
          <Button
            goTo="#pricing"
            textColor="#fff"
            gradient={['#29ABE2', '#2973E2']}
            label={t('ui.general.requestDemo')}
          />
        </S.MobileButtonContainer>
      </S.RightSideContainer>
    </S.Container>
  )
}

export default Section
