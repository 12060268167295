import { FC } from 'react'
import { Text } from 'components/Text'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { InputNames } from '../Section/Section'

import * as S from './styles'

type Props = {
  label: string
  name: 'email' | 'lastName' | 'firstName' | 'phone' | 'message'
  id: string
  type?: string
  register: UseFormRegister<InputNames>
  errors?: FieldErrors
  textarea?: boolean
  placeholder?: string
  isModal?: boolean
  defaultValue?: string
}

const Input: FC<Props> = (props) => {
  const {
    label,
    id,
    name,
    type = 'text',
    register,
    errors,
    defaultValue,
    textarea,
    placeholder,
    isModal = false
  } = props

  return (
    <S.Container>
      <Text
        tag="label"
        htmlFor={id}
        font="roboto-regular"
        weight={400}
        size="20px"
        color={isModal ? '#fff' : '#646464'}
      >
        {label}
      </Text>
      <S.InputContainer>
        {textarea ? (
          <S.TextArea
            rows={4}
            id={id}
            isModal={isModal}
            {...register(name)}
            maxLength={250}
            placeholder={placeholder}
            defaultValue={defaultValue}
          />
        ) : (
          <S.TextInput
            id={id}
            isModal={isModal}
            type={type}
            {...register(name)}
            placeholder={placeholder}
            defaultValue={defaultValue}
          ></S.TextInput>
        )}
        <Text
          font="roboto-regular"
          weight={600}
          size="1rem"
          color="#ff3333"
          className="input-errors-message"
        >
          {errors?.[name]?.message}
        </Text>
      </S.InputContainer>
    </S.Container>
  )
}

export default Input
