import { FC } from 'react'
import { default as MaterialPopover } from '@mui/material/Popover'

type Props = {
  popoverAnchor: HTMLImageElement | null
  setPopoverAnchor: React.Dispatch<
    React.SetStateAction<HTMLImageElement | null>
  >
  children: React.ReactNode
}

const Popover: FC<Props> = ({ popoverAnchor, setPopoverAnchor, children }) => {
  return (
    <>
      <MaterialPopover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        style={{ marginTop: '9px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {children}
      </MaterialPopover>
    </>
  )
}

export default Popover
