import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SectionText } from 'components/SectionText'
import { Fade } from 'react-awesome-reveal'

import * as S from './styles'

const Section: FC = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <Fade direction="left" triggerOnce>
        <SectionText isBlue title={t('ui.general.superpowers')} />
      </Fade>
    </S.Container>
  )
}

export default Section
