import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1295px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: space-between;
  justify-content: flex-start;
  gap: 85px;

  .section-text__container {
    margin-top: 165px;

    @media (max-width: 500px) {
      margin-top: 65px;
    }
  }
`
