import { Text } from 'components/Text'
import styled from 'styled-components'

type Props = {
  isModalVersion: boolean
}

export const Container = styled.div<Props>`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  @media (max-width: 1030px) {
    ${({ isModalVersion }) =>
      !isModalVersion &&
      `
    
    margin-left: 30px;
    margin-right: 30px;`};

    h3 {
      font-size: 22px;
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    gap: 20px;

    .input-errors-message {
      font-size: 0.95rem !important;
    }

    h2 {
      font-size: 1.875rem !important;
      line-height: 1.2;
    }
    h3 {
      font-size: 18px;
    }
  }
`

export const Form = styled.form<Props>`
  width: ${({ isModalVersion }) => (isModalVersion ? '90%' : '100%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  max-width: ${({ isModalVersion }) => (isModalVersion ? '668px' : '800px')};

  a {
    font-weight: bold;
    text-decoration: underline;
    color: inherit;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 500px) {
    gap: 15px;
  }
`

export const PrivacyText = styled(Text).attrs({
  size: '20px',
  font: 'roboto-regular',
  align: 'left'
})`
  width: 100%;
  line-height: 1.2;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 1.3;
  }
`

export const SubmitButton = styled.button<Props>`
  height: 57px;
  width: ${({ isModalVersion }) =>
    isModalVersion ? '322px' : 'clamp(200px, 17vw, 293px);'};

  background: linear-gradient(90deg, #29abe2 0%, #2973e2 100%);
  background: ${({ isModalVersion }) =>
    isModalVersion
      ? '#fff'
      : 'linear-gradient(90deg, #29abe2 0%, #2973e2 100%)'};

  border: none;
  outline: none;
  border-radius: 100px;
  min-height: ${({ isModalVersion }) => (isModalVersion ? '79px' : '50px')};
  transition: 0.5s;
  margin-top: ${({ isModalVersion }) => (isModalVersion ? '5px' : '40px')};
  box-shadow: 0px 5px 11px -3px rgba(0, 0, 0, 0.05);

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 500px) {
    height: 30px;
    padding: 10px 17px;
    margin-top: 20px;
    ${({ isModalVersion }) => isModalVersion && 'width: 90%'};
    ${({ isModalVersion }) => isModalVersion && 'height: 50px'};
    ${({ isModalVersion }) => isModalVersion && 'min-height: 50px'};

    p {
      font-size: 1.125rem;
    }
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .spinner-mobile {
    display: none;
  }

  @media (max-width: 500px) {
    .spinner-mobile {
      display: inline-block;
    }

    .spinner {
      display: none;
    }
  }
`
