import styled from 'styled-components'

type FeatureContainerProps = {
  textMaxWidth: string
}

type IconProps = {
  width: number
  height: number
}

export const Container = styled.div`
  max-width: 1295px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 80px;
  column-gap: 6vw;

  @media (max-width: 1355px) {
    margin-left: 50px;
    margin-right: 50px;
  }

  @media (max-width: 1030px) {
    display: flex;
    flex-direction: column;
    gap: 21px;
  }

  @media (max-width: 500px) {
    margin-top: 25px;
    margin-left: 28px;
    margin-right: 28px;
  }
`

export const FeatureContainer = styled.div<FeatureContainerProps>`
  display: flex;
  align-items: center;
  gap: 24px;

  p {
    max-width: ${({ textMaxWidth }) => textMaxWidth};
  }

  @media (max-width: 1030px) {
    p {
      max-width: 750px;
    }
  }

  @media (max-width: 500px) {
    gap: 11px;

    p {
      font-size: 1rem;
      line-height: 1.2 !important;
    }
  }
`

export const IconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 84px;
  height: 84px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 5px 5px 15px -2px rgba(0, 0, 0, 0.25);

  @media (max-width: 500px) {
    min-width: 40px;
    height: 40px;
  }
`

export const Icon = styled.img<IconProps>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};

  @media (max-width: 500px) {
    width: ${({ width }) => `${width / 2}px`};
    height: ${({ height }) => `${height / 2}px`};
  }
`
