import { useTranslation } from 'react-i18next'
import PhoneIcon from 'assets/images/phone.png'
import RepositoryIcon from 'assets/images/repo.png'
import RolesIcon from 'assets/images/role.png'
import SecurityIcon from 'assets/images/secure.png'
import TagIcon from 'assets/images/tag.png'
import EditIcon from 'assets/images/edit.png'

export const FEATURES = () => {
  const { t } = useTranslation()

  return [
    {
      id: 1,
      description: t('ui.general.personalized'),
      image: EditIcon,
      width: 36,
      height: 36,
      textMaxWidth: '220px'
    },
    {
      id: 2,
      description: t('ui.general.employees'),
      image: PhoneIcon,
      width: 51,
      height: 51,
      textMaxWidth: '320px'
    },
    {
      id: 3,
      description: t('ui.general.secure'),
      image: SecurityIcon,
      width: 37,
      height: 42,
      textMaxWidth: '311px'
    },
    {
      id: 4,
      description: t('ui.general.roles'),
      image: RolesIcon,
      width: 56,
      height: 33,
      textMaxWidth: '240px'
    },
    {
      id: 5,
      description: t('ui.general.tag'),
      image: TagIcon,
      width: 38,
      height: 38,
      textMaxWidth: '352px'
    },
    {
      id: 6,
      description: t('ui.general.layer'),
      image: RepositoryIcon,
      width: 50,
      height: 54,
      textMaxWidth: '572px'
    }
  ]
}
