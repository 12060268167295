import ShouldRender from 'components/ShouldRender'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import Close from './Close/Close'
import * as S from './styles'

type Props = {
  onClose(): void
  height?: React.CSSProperties['height']
  minHeight?: React.CSSProperties['minHeight']
  padding?: React.CSSProperties['padding']
  children: React.ReactNode
  visible?: boolean
  hideCloseIcon?: boolean
  blockOutsideClick?: boolean
}

const Modal: React.FC<Props> = ({
  onClose,
  minHeight,
  height = '485px',
  children,
  padding,
  visible,
  blockOutsideClick = false
}: Props) => {
  const [alreadyVisible, setAlreadyVisible] = useState(false)
  const [closed, setClosed] = useState(false)
  const [modalAnimationCompleted, setModalAnimationCompleted] = useState(false)
  const [contentAnimationCompleted, setContentAnimationCompleted] =
    useState(false)

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (!blockOutsideClick && event.target === event.currentTarget) {
        onClose?.()
      }
    },
    [onClose, blockOutsideClick]
  )

  const contentClassName = useMemo(() => {
    const className = `animate__animated`

    if (visible) {
      return `${className} animate__zoomIn animate__faster`
    }

    return `${className} animate__zoomOut animate__faster`
  }, [visible])

  const containerClassName = useMemo(() => {
    const className = `animate__animated`

    if (visible) {
      return `${className} animate__fadeIn`
    }

    return `${className} animate__fadeOut pointer-events__none`
  }, [visible])

  const handleAnimationEnd = useCallback((event: React.AnimationEvent) => {
    if (event.target !== event.currentTarget) return

    if (event.animationName === 'zoomOut') {
      setContentAnimationCompleted(true)
    }

    if (event.animationName === 'fadeOut') {
      setModalAnimationCompleted(true)
    }
  }, [])

  useEffect(() => {
    const allAnimationCompleted =
      modalAnimationCompleted && contentAnimationCompleted

    if (allAnimationCompleted) {
      setClosed(true)
      setAlreadyVisible(false)
    }
  }, [modalAnimationCompleted, contentAnimationCompleted])

  useEffect(() => {
    if (visible) {
      setClosed(false)
      setAlreadyVisible(true)
      setModalAnimationCompleted(false)
      setContentAnimationCompleted(false)
    }
  }, [visible])

  return (
    <ShouldRender if={alreadyVisible && !closed}>
      <S.Container
        onClick={handleClick}
        className={containerClassName}
        onAnimationEnd={handleAnimationEnd}
      >
        <S.Content
          style={{ height, minHeight, padding }}
          className={contentClassName}
          onAnimationEnd={handleAnimationEnd}
        >
          <Close onClick={onClose} />
          {children}
        </S.Content>
      </S.Container>
    </ShouldRender>
  )
}

export default Modal
