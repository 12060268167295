import { FC } from 'react'

import * as S from './styles'
import ShouldRender from 'components/ShouldRender'

type Props = {
  title: string
  subtitle?: string
}

const Feature: FC<Props> = ({ title, subtitle }) => (
  <S.Container className="feature-container">
    <S.TextContainer>
      <S.Title>{title}</S.Title>
      <ShouldRender if={subtitle}>
        <S.Subtitle>{subtitle}</S.Subtitle>
      </ShouldRender>
    </S.TextContainer>
  </S.Container>
)

export default Feature
