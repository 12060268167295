import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1295px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: space-between;
  justify-content: flex-start;
  flex-direction: column;
  gap: 75px;

  @media (max-width: 500px) {
    gap: 25px;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PartnersImage = styled.img`
  width: 95%;
  height: auto;

  @media (max-width: 720px) {
    display: none;
  }
`

export const PartnersImageMobile = styled.img`
  display: none;
  width: 83%;
  height: auto;

  @media (max-width: 720px) {
    display: block;
  }
`
