import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  max-width: 1920px;
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 302px;

  .first {
    p {
      max-width: 270px;
    }
  }

  .bulletpoint__container:not(.first) {
    p {
      max-width: 375px;
    }
  }

  @media (max-width: 1030px) {
    padding-bottom: 62px;

    .first {
      margin-top: 60px;
    }

    .bulletpoint__container:not(.first) {
      margin-top: 30px;
    }
  }

  @media (max-width: 500px) {
    .first {
      margin-top: 28px;
    }

    .bulletpoint__container:not(.first) {
      margin-top: 10px;
    }
  }
`

export const BackgroundCircle = styled.img`
  overflow: hidden;
  position: absolute;
  top: -350px;
  right: 0;

  @media (max-width: 1030px) {
    display: none;
  }
`
