import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1295px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: space-between;
  justify-content: flex-start;
  gap: 30px;
  padding-top: 165px;

  @media (max-width: 1355px) {
    justify-content: center;
    flex-direction: column;
  }

  @media (max-width: 500px) {
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 57px;
  }
`
export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;

  .section-text__extra-text {
    margin-top: 67px;
    max-width: 420px;

    @media (max-width: 500px) {
      font-size: 1.125rem;
      margin-top: 23px;
      max-width: 250px;
    }
  }

  .section-text__extra-text2 {
    margin-top: 40px;
    max-width: 420px;

    @media (max-width: 500px) {
      font-size: 1.125rem;
      margin-top: 15px;
      max-width: 200px;
    }
  }

  .section-text__subtitle {
    margin-top: 47px !important;
    max-width: 530px;
    line-height: 1.1;

    @media (max-width: 1420px) {
      max-width: 500px;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }
`

export const ExtraTextSpan = styled.span`
  color: #2980e2;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 1355px) {
    margin-left: 50px;
    margin-right: 50px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`

export const MobileButtonContainer = styled.div`
  display: none;

  @media (max-width: 500px) {
    display: flex;
    align-items: center;
  }
`

export const RightSideContainer = styled.div`
  position: relative;

  .subtitle-mobile {
    display: none;
  }

  @media (max-width: 1355px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  @media (max-width: 500px) {
    align-self: center;
    flex-direction: column;

    .subtitle-mobile {
      display: block;
      margin-top: 65px;
      max-width: 330px;
    }
  }
`

export const NotebookImage = styled.img`
  position: absolute;
  top: 80px;
  width: clamp(500px, 47vw, 907px);
  height: clamp(200px, 27vw, 530px);
  z-index: 3;

  @media (max-width: 1355px) {
    position: relative;
    top: 0;
    width: 85vw;
    height: 50vw;
  }

  @media (max-width: 500px) {
    top: -30px;
  }
`
