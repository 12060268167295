import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SectionText } from 'components/SectionText'

import * as S from './styles'

const Section: FC = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <SectionText title={t('ui.general.whatWeDo')} />
    </S.Container>
  )
}

export default Section
