import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'components/Text'
import BeLogo from 'assets/images/be-logo.png'

import * as S from './styles'

const Footer: FC = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.Logo src={BeLogo} />
      <Text font="roboto-regular" weight={500} size="1.3rem" color="#fff">
        {t('ui.general.footer')}
      </Text>
    </S.Container>
  )
}

export default Footer
