import { FC } from 'react'
import { FEATURES } from './_constants'
import { Text } from 'components/Text'

import * as S from './styles'

const Features: FC = () => (
  <S.Container>
    {FEATURES().map((feature) => (
      <S.FeatureContainer textMaxWidth={feature.textMaxWidth} key={feature.id}>
        <S.IconBackground>
          <S.Icon
            width={feature.width}
            height={feature.height}
            src={feature.image}
          />
        </S.IconBackground>
        <Text
          color="#fff"
          font="montserrat-medium"
          weight={500}
          size="1.6rem"
          style={{
            lineHeight: '35px'
          }}
        >
          {feature.description}
        </Text>
      </S.FeatureContainer>
    ))}
  </S.Container>
)

export default Features
