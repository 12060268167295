import { FC } from 'react'
import { Text } from 'components/Text'
import { useInView } from 'react-intersection-observer'

import * as S from './styles'

export type Props = {
  width: number
  mediumWidth: number
  mobileWidth: number
  id: number
  children: React.ReactNode
  gradient: string
  top: string
}

const BulletPoint: FC<Props> = (props) => {
  const { width, mediumWidth, children, gradient, top, id, mobileWidth } = props

  const { ref, inView } = useInView({
    triggerOnce: true
  })

  return (
    <S.Container
      ref={ref}
      inView={inView}
      className={`bulletpoint__container ${id === 1 && 'first'}`}
      id={id === 1 ? 'our-tech' : ''}
      gradient={gradient}
      width={width}
      mediumWidth={mediumWidth}
      mobileWidth={mobileWidth}
      top={top}
    >
      <Text font="montserrat-bold" size="1.75rem" color="#fff" upperCase>
        {children}
      </Text>
    </S.Container>
  )
}

export default BulletPoint
