import styled from 'styled-components'

type ButtonProps = {
  backgroundColor?: string
  marginTop?: string
  gradient?: [string, string]
}

export const Button = styled.button<ButtonProps>`
  padding: 16px 27px;
  background: ${({ backgroundColor }) => backgroundColor && backgroundColor};
  border: none;
  outline: none;
  margin-top: ${({ marginTop }) => marginTop};
  border-radius: 100px;
  box-shadow: 0px 5px 11px -3px rgba(0, 0, 0, 0.5);
  transition: 0.5s;

  ${({ gradient }) =>
    gradient &&
    `  
  background-image: linear-gradient(
    to right,
    ${gradient[0]} 0%,
    ${gradient[1]} 51%,
    ${gradient[0]} 100%
  );
  background-size: 200% auto;`}

  &:hover {
    transform: scale(1.05);
    ${({ gradient }) => gradient && 'background-position: right center;'};
  }

  @media (max-width: 500px) {
    padding: 10px 17px;
    margin-top: 51px;

    a {
      font-size: 1.125rem !important;
    }
  }
`
