import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const Schema = () => {
  const { t } = useTranslation()

  return yup.object().shape({
    firstName: yup
      .string()
      .required(t('ui.contactForm.required'))
      .min(1)
      .max(250),
    lastName: yup
      .string()
      .required(t('ui.contactForm.required'))
      .min(1)
      .max(250),
    email: yup
      .string()
      .email(t('ui.contactForm.emailInvalid'))
      .required(t('ui.contactForm.required'))
      .min(1)
      .max(250),
    phone: yup.string().required(t('ui.contactForm.required')).min(1).max(250),
    message: yup.string().required(t('ui.contactForm.required')).min(1).max(250)
  })
}
