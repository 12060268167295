import { Text } from 'components/Text'
import styled from 'styled-components'

export const Container = styled.div`
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  border: 1px solid #00000013;
  height: 70px;

  @media (max-width: 1030px) {
    height: 100px;
  }

  @media (max-width: 310px) {
    height: 110px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  margin-right: 5px;
  gap: 2px;

  @media (max-width: 1030px) {
    gap: 5px;
  }

  @media (max-width: 450px) {
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
`

export const Subtitle = styled(Text).attrs({
  font: 'roboto-regular',
  size: '14px',
  color: '#454545'
})`
  line-height: 1.1;

  @media (max-width: 675px) {
    font-size: 14px;
  }

  @media (max-width: 450px) {
    font-size: 12px;
    margin-top: 5px;
  }

  @media (max-width: 310px) {
    font-size: 11px;
  }
`

export const Title = styled(Text).attrs({
  font: 'roboto-bold',
  size: '17px',
  color: '#454545'
})`
  line-height: 1.1;

  @media (max-width: 675px) {
    font-size: 15px;
  }

  @media (max-width: 450px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }
`
