import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'components/Text'
import { Fade } from 'react-awesome-reveal'
import MagnetIcon from 'assets/images/magnet.png'
import RocketIcon from 'assets/images/rocket.png'
import LeftSidePhoneImage from 'assets/images/intro-left-side-phone.png'
import RightSidePhoneImage from 'assets/images/intro-right-side-phone.png'
import VideoPoster from 'assets/images/video-placeholder.png'
import { Button } from 'components/Button'
import { SectionText } from 'components/SectionText'

import * as S from './styles'

const Section: FC = () => {
  const { t } = useTranslation()

  const videoWEBM =
    'https://be-static-assets.s3.eu-central-1.amazonaws.com/collabostories-landing/intro-video.webm'
  const videoMP4 =
    'https://be-static-assets.s3.eu-central-1.amazonaws.com/collabostories-landing/intro-video.mp4'

  return (
    <S.Container id="intro">
      <Fade direction="left" triggerOnce>
        <SectionText
          title={t('ui.general.yourCompany')}
          subTitle={t('ui.general.collaboration')}
          extraTextPosition="end"
          extraText={
            <>
              <S.PhonesPictureContainerMobile>
                <S.LeftSidePhoneContainer>
                  <S.FrameContainer>
                    <S.PhoneFrame src={LeftSidePhoneImage} />
                    <S.Video autoPlay muted loop controls poster={VideoPoster}>
                      <S.VideoSource src={videoWEBM} type="video/webm" />
                      <S.VideoSource src={videoMP4} type="video/mp4" />
                    </S.Video>
                  </S.FrameContainer>
                </S.LeftSidePhoneContainer>
                <S.RightSidePhone src={RightSidePhoneImage} />
              </S.PhonesPictureContainerMobile>
              <S.TextAndIconContainer style={{ marginTop: '40px' }}>
                <S.Icon src={MagnetIcon} />
                <Text
                  tag="p"
                  font="montserrat-regular"
                  size="1.125rem"
                  color="white"
                >
                  {t('ui.general.fastestWay')}
                </Text>
              </S.TextAndIconContainer>
              <S.TextAndIconContainer style={{ marginTop: '15px' }}>
                <S.Icon src={RocketIcon} />
                <Text
                  tag="p"
                  font="montserrat-regular"
                  size="1.125rem"
                  color="white"
                >
                  {t('ui.general.perfectTool')}
                </Text>
              </S.TextAndIconContainer>
              <Button
                goTo="#pricing"
                textColor="#2981e2"
                backgroundColor="#fff"
                label={t('ui.general.requestTrial')}
                marginTop="39px"
              />
            </>
          }
        />
      </Fade>
      <Fade direction="right" triggerOnce>
        <S.PhonesPictureContainer>
          <S.LeftSidePhoneContainer>
            <S.FrameContainer>
              <S.PhoneFrame src={LeftSidePhoneImage} />
              <S.Video autoPlay muted loop controls poster={VideoPoster}>
                <S.VideoSource src={videoWEBM} type="video/webm" />
                <S.VideoSource src={videoMP4} type="video/mp4" />
              </S.Video>
            </S.FrameContainer>
          </S.LeftSidePhoneContainer>
          <S.RightSidePhone src={RightSidePhoneImage} />
        </S.PhonesPictureContainer>
      </Fade>
    </S.Container>
  )
}

export default Section
