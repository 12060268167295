import { Header } from 'components/Header'
import { Section } from 'components/Intro/Section'
import { FC } from 'react'

import * as S from './styles'

const Intro: FC = () => (
  <S.Container>
    <Header />
    <Section />
  </S.Container>
)

export default Intro
