import { FC } from 'react'
import { SectionText } from 'components/SectionText'
import { Fade } from 'react-awesome-reveal'
import PartnersImage from 'assets/images/partners.jpg'
import PartnersImageMobile from 'assets/images/partners-mobile.png'

import * as S from './styles'

const Section: FC = () => (
  <S.Container>
    <Fade direction="left" triggerOnce>
      <SectionText isBlue title="Partners" />
      <S.ImageContainer>
        <S.PartnersImage src={PartnersImage} />
        <S.PartnersImageMobile src={PartnersImageMobile} />
      </S.ImageContainer>
    </Fade>
  </S.Container>
)

export default Section
