import { Text } from 'components/Text'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  background: linear-gradient(112.37deg, #29abe2 8%, #2973e2 74.06%);
  max-width: 1925px;
  margin-left: auto;
  margin-right: auto;
  padding: 82px 0;
  overflow: hidden;
`

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  z-index: 4;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`

export const Title = styled(Text).attrs({
  size: '50px',
  font: 'montserrat-bold',
  color: '#fff'
})`
  @media (max-width: 500px) {
    font-size: 42px;
  }
`

export const Subtitle = styled(Text).attrs({
  size: '29px',
  font: 'montserrat-regular',
  color: '#fff'
})`
  @media (max-width: 500px) {
    font-size: 23px;
  }
`

export const Table = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;

  @media (max-width: 1285px) {
    width: 90%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const Features = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  width: 25%;
  max-width: 302px;
  z-index: 2;

  .feature-container:first-child {
    border-top-left-radius: 10px;
  }

  .feature-container:last-child {
    border-bottom-left-radius: 10px;
  }

  @media (max-width: 1030px) {
    margin-bottom: 100px;
  }

  @media (max-width: 600px) {
    width: 35%;
  }

  @media (max-width: 450px) {
    width: 28%;
  }

  @media (max-width: 380px) {
    width: 30%;
  }

  @media (max-width: 310px) {
    margin-bottom: 110px;
    width: 31%;
  }
`

export const Models = styled.div`
  display: flex;
  width: 75%;
  max-width: 936px;

  .model-container:first-child .model-header {
    border-top-left-radius: 10px;
  }

  .model-container:first-child .model-content {
    border-bottom-left-radius: 10px;
  }

  .model-container:last-child .model-header {
    border-top-right-radius: 10px;
  }

  .model-container:last-child .model-content {
    border-bottom-right-radius: 10px;
  }

  @media (max-width: 600px) {
    width: 60%;
  }

  @media (max-width: 450px) {
    width: 70%;
    margin-bottom: 0;
  }

  @media (max-width: 380px) {
    width: 68%;
  }
`

export const BackgroundBottomLeftCircle = styled.img`
  position: absolute;
  left: -160px;
  bottom: 20px;
`

export const BackgroundTopRightCircle = styled.img`
  position: absolute;
  right: -220px;
  top: 20px;
`
