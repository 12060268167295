import { useTranslation } from 'react-i18next'

export const BULLETPOINTS = () => {
  const { t } = useTranslation()

  return [
    {
      id: 1,
      content: t('ui.general.integration'),
      width: 47.4,
      mediumWidth: 60.2, //74.2
      mobileWidth: 227,
      gradient: '180deg, #29ABE2 0%, #2973E2 503.33%',
      top: '-65px'
    },
    {
      id: 2,
      content: t('ui.general.plug'),
      width: 48.4,
      mediumWidth: 70,
      mobileWidth: 262,
      gradient: '180deg, #29ABE2 -137.78%, #2973E2 368.89%',
      top: '40px'
    },
    {
      id: 3,
      content: t('ui.general.linkingAssets'),
      width: 52.7,
      mediumWidth: 90,
      mobileWidth: 325,
      gradient: '180deg, #29ABE2 -263.33%, #2973E2 238.89%',
      top: '148px'
    },
    {
      id: 4,
      content: t('ui.general.simplified'),
      width: 45.7,
      mediumWidth: 68,
      mobileWidth: 256,
      gradient: '180deg, #29ABE2 -406.67%, #2973E2 100%',
      top: '254px'
    }
  ]
}
