import { FC } from 'react'
import { Section } from './Section'
import { BulletPoint } from './BulletPoint'
import { BULLETPOINTS } from './_constants'
import Circle from 'assets/images/right-circle.png'

import * as S from './styles'

const Techsuperpower: FC = () => (
  <S.Container id="our-tech-mobile">
    <S.BackgroundCircle src={Circle} />
    <Section />
    {BULLETPOINTS().map((point) => (
      <BulletPoint
        gradient={point.gradient}
        key={point.id}
        width={point.width}
        mediumWidth={point.mediumWidth}
        mobileWidth={point.mobileWidth}
        top={point.top}
        id={point.id}
      >
        {point?.content}
      </BulletPoint>
    ))}
  </S.Container>
)

export default Techsuperpower
