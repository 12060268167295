import styled from 'styled-components'

export const Container = styled.header`
  max-width: 1570px;
  padding: 19px 0 0 0;
  margin-left: auto;
  margin-right: auto;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;

  @media (max-width: 1420px) {
    justify-content: space-evenly;
  }

  @media (max-width: 1190px) {
    justify-content: space-between;
    margin: 0 50px;
  }

  @media (max-width: 500px) {
    margin: 0 28px;
  }
`

export const Logo = styled.img`
  width: 310px;
  height: 77px;

  @media (max-width: 500px) {
    width: 155px;
    height: 39px;
  }
`

export const Navigation = styled.nav`
  @media (max-width: 1190px) {
    display: none;
  }
`

export const ListOfLinks = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8.5px;
  gap: 3.75rem;
`

export const ListItem = styled.li`
  list-style: none;
`

export const ButtonAndMenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`

export const Button = styled.button`
  background-image: linear-gradient(
    to right,
    #29abe2 0%,
    #2973e2 51%,
    #29abe2 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  border: none;
  border-radius: 100px;
  padding: 13px 30px;
  margin-top: 15px;
  box-shadow: 0px 5px 11px -3px rgba(0, 0, 0, 0.5);

  &:hover {
    transform: scale(1.05);
    background-position: right center;
  }

  @media (max-width: 1420px) {
    display: none;
  }
`

export const HamburguerMenuIcon = styled.img`
  display: none;
  width: 40px;
  height: 32px;
  margin-top: 10px;

  @media (max-width: 1190px) {
    display: inline-block;
  }

  @media (max-width: 500px) {
    width: 24px;
    height: 16px;
    margin-top: 5px;
  }
`

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  padding: 20px 16px;

  @media (max-width: 500px) {
    gap: 1.3rem;
    padding: 15px 12px;

    a {
      font-size: 16px;
    }
  }
`
