import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .top {
    top: calc(30px * -1);
  }

  .right {
    left: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .bottom {
    bottom: calc(30px * -1);
  }

  .left {
    left: auto;
    right: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
`

export const Content = styled.div<{
  backgroundColor: string
  borderColor: string
}>`
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  background: ${({ backgroundColor }) => backgroundColor};
  z-index: 100;
  white-space: nowrap;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};

  ::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: calc(6px * -1);
  }
`
