import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  background: rgba(0, 0, 0, 0.9) none repeat scroll 0% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9998;
  left: 0;

  &.animate__fadeIn {
    --animate-duration: 0.4s;
  }
`

export const Content = styled.div`
  width: 80%;
  max-width: 737px;
  border-radius: 10px;
  background: linear-gradient(112.37deg, #29abe2 8%, #2973e2 74.06%);
  padding: 18px;
  transition: height 0.5s ease-in-out;
  padding-top: 80px;

  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
