import React, { FC, useState } from 'react'
import { Text } from 'components/Text'
import 'animate.css'
import * as S from './styles'

type Props = {
  delay?: number
  children: React.ReactNode
  direction?: 'left' | 'right' | 'top' | 'bottom'
  content?: string
  className?: string
  backgroundColor?: React.CSSProperties['backgroundColor']
  borderColor?: React.CSSProperties['borderColor']
  textColor?: React.CSSProperties['color']
  onClick?: boolean
}

const Tooltip: FC<Props> = (props) => {
  const {
    delay = 300,
    children,
    direction = 'top',
    className,
    content,
    backgroundColor = '#fff;',
    textColor = '#6F6F6F',
    borderColor = '#6F6F6F'
  } = props

  const [active, setActive] = useState(false)
  const [animatedClass, setAnimatedClass] = useState('')

  const showTip = () => {
    setAnimatedClass('animate__fadeIn animate__faster')
    setTimeout(() => {
      setActive(true)
    }, delay)
  }

  const hideTip = () => {
    setAnimatedClass('animate__fadeOut animate__faster')
    setTimeout(() => {
      setActive(false)
    }, delay)
  }

  return (
    <S.Container onClick={active ? hideTip : showTip} className={className}>
      {children}
      {active && (
        <S.Content
          className={`Tooltip-Tip ${direction} animate__animated ${animatedClass}`}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
        >
          <Text size="14px" font="roboto-medium" color={textColor}>
            {content}
          </Text>
        </S.Content>
      )}
    </S.Container>
  )
}

export default Tooltip
