import { FC, useCallback, useState } from 'react'
import ShouldRender from 'components/ShouldRender'
import CheckmarkTrue from 'assets/images/checkmark-true.svg'
import CheckmarkFalse from 'assets/images/checkmark-false.svg'
import { useTranslation } from 'react-i18next'
import ArrowDown from 'assets/images/arrow.svg'
import { RequestDemo } from 'components/RequestDemo'

import * as S from './styles'
import { Modal } from './Modal'
import { ModelProps } from '../_constants'
import { Tooltip } from './Tooltip'

const Model: FC<ModelProps> = (props) => {
  const { header, features, buttonText, title } = props
  const { t } = useTranslation()

  const isPriceNumber = typeof header?.price === 'number'

  const [openModal, setOpenModal] = useState(false)

  const toggleModal = useCallback(
    (value: boolean) => () => setOpenModal(value),
    []
  )

  return (
    <>
      <S.Container className="model-container">
        <S.Header color={header?.color} className="model-header">
          <S.HeaderTitle>
            <S.ModelTitle>{title}</S.ModelTitle>

            <ShouldRender if={isPriceNumber}>
              <S.PriceNumber>
                €{header?.price}
                <S.SlashMonth>{t('ui.month')}</S.SlashMonth>
                <S.PerMonth>{t('ui.perMonth')}</S.PerMonth>
              </S.PriceNumber>
            </ShouldRender>

            <ShouldRender if={!isPriceNumber}>
              <S.PriceText>{header?.price}</S.PriceText>

              <S.MobilePriceText>{t('ui.letsTalk')}</S.MobilePriceText>
            </ShouldRender>
          </S.HeaderTitle>
        </S.Header>

        <S.Content className="model-content">
          {features?.map((feature) => (
            <S.Feature key={feature?.id}>
              <ShouldRender if={feature?.type === 'text'}>
                <S.FeatureText>{feature?.info}</S.FeatureText>
                <S.UnlimitedIcon />
              </ShouldRender>

              <ShouldRender if={feature?.type === 'number'}>
                <S.FeatureNumber>{feature?.info}</S.FeatureNumber>
              </ShouldRender>

              <ShouldRender if={feature?.type === 'storage'}>
                <S.FeatureStorage>
                  {feature?.info} <S.Gigabytes>GB</S.Gigabytes>
                </S.FeatureStorage>
              </ShouldRender>

              <ShouldRender if={feature?.type === 'check'}>
                <ShouldRender if={feature?.info === true}>
                  <S.Checkmark src={CheckmarkTrue} />
                </ShouldRender>
                <ShouldRender if={feature?.info === false}>
                  <S.Checkmark src={CheckmarkFalse} />
                </ShouldRender>

                <ShouldRender if={feature?.extraInfo}>
                  <S.ExtraInfo>{feature?.extraInfo}</S.ExtraInfo>

                  <Tooltip
                    content={feature?.extraInfo}
                    direction="bottom"
                    className="tooltip"
                  >
                    <S.ArrowDown src={ArrowDown} />
                  </Tooltip>
                </ShouldRender>
              </ShouldRender>
            </S.Feature>
          ))}
          <S.ButtonContainer className="button-container">
            <S.Button onClick={toggleModal(true)}>
              <S.ButtonLabel>{buttonText}</S.ButtonLabel>
              <S.ButtonIcon />
            </S.Button>
          </S.ButtonContainer>
        </S.Content>
      </S.Container>

      <Modal visible={openModal} onClose={toggleModal(false)} height="92%">
        <RequestDemo
          isModalVersion
          closeModal={toggleModal(false)}
          contactMessage={t('ui.contactMessage', { planName: title })}
        />
      </Modal>
    </>
  )
}

export default Model
