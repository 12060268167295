import { Text } from 'components/Text'
import { ImInfinite } from 'react-icons/im'
import { MdOpenInNew } from 'react-icons/md'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 25%;
`

export const Header = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  width: 100%;
  height: 170px;
  max-height: 170px;
`

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
  height: 100%;
`

export const ModelTitle = styled(Text).attrs({
  size: '17px',
  font: 'roboto-bold',
  color: '#fff',
  align: 'center'
})`
  max-width: 98%;

  @media (max-width: 600px) {
    font-size: 15px;
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`

export const PriceNumber = styled(Text).attrs({
  size: '50px',
  color: '#fff',
  font: 'roboto-bold'
})`
  @media (max-width: 1285px) {
    font-size: 38px;
  }

  @media (max-width: 600px) {
    font-size: 25px;
  }

  @media (max-width: 450px) {
    font-size: 22px;
    text-align: center;
  }
`

export const SlashMonth = styled(Text).attrs({
  size: '12px',
  tag: 'span',
  color: '#fff',
  font: 'roboto-bold'
})`
  @media (max-width: 1285px) {
    display: none;
  }
`

export const PerMonth = styled(Text).attrs({
  size: '12px',
  color: '#fff',
  font: 'roboto-bold',
  align: 'center'
})`
  display: none;

  @media (max-width: 1285px) {
    display: block;
  }

  @media (max-width: 450px) {
    max-width: 40px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.1;
  }
`

export const PriceText = styled(Text).attrs({
  font: 'roboto-medium',
  size: '14px',
  color: '#fff',
  align: 'center'
})`
  width: 97%;
  line-height: 1.1;

  @media (max-width: 820px) {
    display: none;
  }
`

export const MobilePriceText = styled(Text).attrs({
  font: 'roboto-bold',
  size: '27px',
  color: '#fff',
  align: 'center'
})`
  width: 97%;
  line-height: 1.1;
  display: none;

  @media (max-width: 820px) {
    display: block;
  }

  @media (max-width: 600px) {
    font-size: 23px;
  }
`

export const Content = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Feature = styled.div`
  border: 1px solid #00000013;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70px;

  .tooltip {
    display: none;

    @media (max-width: 1030px) {
      display: flex;
    }
  }

  @media (max-width: 1030px) {
    height: 100px;
  }

  @media (max-width: 310px) {
    height: 110px;
  }
`

export const FeatureNumber = styled(Text).attrs({
  font: 'roboto-bold',
  size: '25px',
  color: '#6f6f6f',
  align: 'center'
})`
  @media (max-width: 600px) {
    font-size: 18px;
  }
`

export const FeatureStorage = styled(FeatureNumber)``

export const Gigabytes = styled(Text).attrs({
  font: 'roboto-medium',
  size: '16px',
  color: '#6f6f6f',
  tag: 'span'
})`
  @media (max-width: 600px) {
    font-size: 13px;
  }
`

export const FeatureText = styled(Text).attrs({
  font: 'roboto-medium',
  size: '16px',
  color: '#6f6f6f'
})`
  @media (max-width: 600px) {
    display: none;
  }
`

export const UnlimitedIcon = styled(ImInfinite).attrs({
  size: 25,
  color: '#6f6f6f'
})`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

export const ExtraInfo = styled(Text).attrs({
  font: 'roboto-regular',
  size: '12px',
  align: 'center',
  color: '#6f6f6f'
})`
  margin-top: 2px;

  @media (max-width: 1030px) {
    display: none;
  }
`

export const Checkmark = styled.img`
  width: 28px;
  height: 28px;

  @media (max-width: 500px) {
    width: 23px;
    height: 23px;
  }
`

export const ArrowDown = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
`

export const ButtonContainer = styled(Feature)``

export const Button = styled.button`
  width: clamp(50px, 70%, 400px);
  height: 80%;
  max-height: 43px;
  background-color: #3453d1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  border-radius: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: opacity 500ms ease;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 800px) {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
`

export const ButtonLabel = styled(Text).attrs({
  font: 'roboto-medium',
  tag: 'a',
  size: '15px',
  color: '#fff',
  align: 'center'
})`
  @media (max-width: 1024px) {
    display: none;
  }
`

export const ButtonIcon = styled(MdOpenInNew).attrs({
  size: 20,
  color: '#fff'
})`
  display: none;
  min-width: 20px;
  min-height: 20px;

  @media (max-width: 1024px) {
    display: block;
  }
`
