const { REACT_APP_ENVIRONMENT } = process.env

type Target = 'production' | 'staging'

const appConfig = {
  isDev: REACT_APP_ENVIRONMENT === 'development',
  isStaging: REACT_APP_ENVIRONMENT === 'staging',
  isProd: REACT_APP_ENVIRONMENT === 'production',
  target: (REACT_APP_ENVIRONMENT === 'production'
    ? 'production'
    : 'staging') as Target
}

export default appConfig
