import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  max-width: 1925px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  padding-bottom: 340px;

  @media (max-width: 1190px) {
    padding-bottom: 140px;
  }

  @media (max-width: 500px) {
    padding-bottom: 75px;
  }
`
export const BackgroundRightCircle = styled.img`
  position: absolute;
  right: 0;
  top: -210px;

  @media (max-width: 1420px) {
    top: -2060px;
  }

  @media (max-width: 820px) {
    display: none;
  }
`

export const BackgroundLeftShape = styled.img`
  position: absolute;

  left: 0;
  top: 185px;
  z-index: 3;

  @media (max-width: 1660px) {
    display: none;
  }
`
