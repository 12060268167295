import styled from 'styled-components'

export const Container = styled.footer`
  max-width: 1925px;
  background: linear-gradient(112.37deg, #29abe2 8%, #2973e2 74.06%);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 20px 0;

  @media (max-width: 1660px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 500px) {
    gap: 10px;
    padding-left: 28px;
    padding-right: 28px;
    padding: 23px;

    p {
      font-size: 0.875rem !important;
      line-height: 1.2;
      text-align: center !important;
    }
  }
`

export const Logo = styled.img`
  width: 85px;
  height: 52px;

  @media (max-width: 500px) {
    width: 41px;
    height: 28px;
  }
`
