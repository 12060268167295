import { FC } from 'react'
import { Text } from 'components/Text'
import BlueLine from 'assets/images/line1.png'
import WhiteLine from 'assets/images/line2.png'

import * as S from './styles'

type Props = {
  isBlue?: boolean
  title: React.ReactNode
  subTitle?: React.ReactNode
  extraText?: React.ReactNode
  extraTextPosition?: 'middle' | 'end'
}

const SectionText: FC<Props> = (props) => {
  const {
    isBlue = false,
    title = 'Title',
    subTitle,
    extraText,
    extraTextPosition = 'middle'
  } = props

  return (
    <S.TextContainer className="section-text__container">
      <S.Line
        src={isBlue ? BlueLine : WhiteLine}
        className="section-text__line"
      />
      <Text
        tag="h2"
        font="montserrat-bold"
        className="section-text__title"
        size="3.125rem"
        color={isBlue ? '#2980e2' : '#fff'}
        style={{ lineHeight: 1.2 }}
      >
        {title}
      </Text>
      {extraTextPosition === 'middle' && extraText && extraText}
      {subTitle && (
        <Text
          tag="h3"
          font="montserrat-regular"
          className="section-text__subtitle"
          size="1.7rem"
          color={isBlue ? '#646464' : '#fff'}
          weight={400}
          style={{ marginTop: '24px' }}
        >
          {subTitle}
        </Text>
      )}
      {extraTextPosition === 'end' && extraText && extraText}
    </S.TextContainer>
  )
}

export default SectionText
