import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  max-width: 1295px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
