import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HeaderLogo from 'assets/images/header-logo.png'
import HamburguerIcon from 'assets/images/hamburguer-icon.png'
import { Text } from '../Text'
import { Popover } from '../Popover'

import * as S from './styles'

const Header: FC = () => {
  const { t } = useTranslation()

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLImageElement | null>(
    null
  )

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLImageElement>) => {
      setPopoverAnchor(event.currentTarget)
    },
    []
  )

  const handleClose = useCallback(() => {
    setPopoverAnchor(null)
  }, [])

  return (
    <S.Container>
      <S.Content>
        <S.Logo src={HeaderLogo} />
        <S.Navigation>
          <S.ListOfLinks>
            <S.ListItem>
              <Text
                href="#intro"
                tag="a"
                font="montserrat-medium"
                size="1.25rem"
                color="#fff"
              >
                {t('ui.header.intro')}
              </Text>
            </S.ListItem>
            <S.ListItem>
              <Text
                href="#our-tech"
                tag="a"
                font="montserrat-medium"
                size="1.25rem"
                color="#fff"
              >
                {t('ui.header.ourTech')}
              </Text>
            </S.ListItem>
            <S.ListItem>
              <Text
                href="#what-we-do"
                tag="a"
                font="montserrat-medium"
                size="1.25rem"
                color="#fff"
              >
                {t('ui.general.whatWeDo')}
              </Text>
            </S.ListItem>
            <S.ListItem>
              <Text
                href="#scalability"
                tag="a"
                font="montserrat-medium"
                size="1.25rem"
                color="#fff"
              >
                {t('ui.header.scalability')}
              </Text>
            </S.ListItem>
            <S.ListItem>
              <Text
                href="#partners"
                tag="a"
                font="montserrat-medium"
                size="1.25rem"
                color="#fff"
              >
                {t('ui.general.partners')}
              </Text>
            </S.ListItem>
          </S.ListOfLinks>
        </S.Navigation>
        <S.ButtonAndMenuContainer>
          <S.Button>
            <Text
              tag="a"
              href="#pricing"
              font="montserrat-medium"
              size="1.25rem"
              align="center"
              color="#fff"
            >
              Request free trial
            </Text>
          </S.Button>
          <S.HamburguerMenuIcon src={HamburguerIcon} onClick={handleClick} />
          <Popover
            popoverAnchor={popoverAnchor}
            setPopoverAnchor={setPopoverAnchor}
          >
            <S.PopoverContent>
              <Text
                tag="a"
                font="montserrat-medium"
                size="1.313rem"
                color="#2981e2"
                href="#intro"
                onClick={handleClose}
              >
                {t('ui.header.intro')}
              </Text>
              <Text
                tag="a"
                font="montserrat-medium"
                size="1.313rem"
                color="#2981e2"
                href="#our-tech-mobile"
                onClick={handleClose}
              >
                {t('ui.header.ourTech')}
              </Text>
              <Text
                tag="a"
                font="montserrat-medium"
                size="1.313rem"
                color="#2981e2"
                href="#what-we-do"
                onClick={handleClose}
              >
                {t('ui.general.whatWeDo')}
              </Text>
              <Text
                tag="a"
                font="montserrat-medium"
                size="1.313rem"
                color="#2981e2"
                href="#scalability"
                onClick={handleClose}
              >
                {t('ui.header.scalability')}
              </Text>
              <Text
                tag="a"
                font="montserrat-medium"
                size="1.313rem"
                color="#2981e2"
                href="#partners"
                onClick={handleClose}
              >
                {t('ui.general.partners')}
              </Text>
            </S.PopoverContent>
          </Popover>
        </S.ButtonAndMenuContainer>
      </S.Content>
    </S.Container>
  )
}

export default Header
