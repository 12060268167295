import { FC } from 'react'
import { Section } from './Section'
import * as S from './styles'

type Props = {
  isModalVersion?: boolean
  closeModal?(): void
  contactMessage?: string
}

const RequestDemo: FC<Props> = (props) => {
  const { isModalVersion = false, closeModal, contactMessage } = props

  return (
    <S.Container id="request-a-demo" isModalVersion={isModalVersion}>
      <Section
        defaultContactMessage={contactMessage}
        isModalVersion={isModalVersion}
        closeModal={closeModal}
      />
    </S.Container>
  )
}

export default RequestDemo
