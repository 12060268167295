import styled from 'styled-components'

export const Container = styled.div<{ isModalVersion: boolean }>`
  max-width: 1925px;
  margin-left: auto;
  margin-right: auto;
  background: ${({ isModalVersion }) =>
    isModalVersion ? 'transparent' : '#fff'};

  ${({ isModalVersion }) =>
    !isModalVersion
      ? `
  
    padding-top: 105px;
    padding-bottom: 105px;

    @media (max-width: 500px) {
      padding-top: 40px;
      padding-bottom: 60px;
    }
    `
      : `
    padding-bottom: 40px; 
    `};
`
