import styled from 'styled-components'

export const TextContainer = styled.div`
  position: relative;
  padding-top: 28px;

  @media (max-width: 1355px) {
    margin-left: 50px;
    margin-right: 50px;
  }

  @media (max-width: 500px) {
    margin-left: 28px;
    margin-right: 28px;

    h2 {
      font-size: 1.875rem;
    }

    h3 {
      font-size: 1.125rem;
      margin-top: 10px !important;
    }
  }
`
export const Line = styled.img`
  position: absolute;
  top: 0;
  width: 58px;
  height: 6px;
`
