import { FC } from 'react'
import { Section } from './Section'
import { Fade } from 'react-awesome-reveal'
import BackgroundShape from 'assets/images/scalable-shape.png'
import BackgroundLeftCircle from 'assets/images/left-circle.png'

import * as S from './styles'

const Scalability: FC = () => (
  <S.Container id="scalability">
    <S.BackgroundShape src={BackgroundShape} />
    <S.BackgroundLeftCircle src={BackgroundLeftCircle} />
    <Fade direction="left" triggerOnce>
      <Section />
    </Fade>
  </S.Container>
)

export default Scalability
